
var campaignImageName = null;

//var fac = new FastAverageColor();
var videoPlaying = false;
var heroVideoPlayed = false;
var hasScrolled = false;
var overlaySwapState = false;
var overlayVisible = true;
var lastBoatTypeOverlay = "";

var isOldIPhone = (window.screen.width === 375 && window.screen.height === 667);

// Array of objects managing video overlays: { name: <video-tag-id>, ref: <db-ref>, played: <true|false> }
var videoOverlayStates = new Array();

$(document).ready(function () {
    if (browserIsMobileSafari) {
        console.log("Using mobile layout (" + $(window).width() + "x" + $(window).height() + ") ...");
        $('#boat-type-overlay-button').hide();
        $('#sailhub-show-detail-button').hide();
    } else {
        console.log("Using desktop layout (" + $(window).width() + "x" + $(window).height() + ") ...");
        $('.mobile').removeClass('mobile').addClass('desktop');
    }

    //$('#raptor-video-overlay').hide();

    var divHeight = getEffectiveWindowHeight();

    // Special case for iPhones in portrait mode
    if ( browserIsMobileSafari && ($(window).width() < 760) ) {
        $(".plx-content").css('min-height', divHeight * 1.22 + 'px');
    } else {
        $(".plx-content").css('min-height', divHeight * 1.1 + 'px');
    }
    cssLayout();

    // Start alternating overlay images if we have a campaign
    if (campaignImageName != null)
    {
        $("#campaign-link").show();
        $("#sailing-world-link").show();

        setInterval(function () {
            if (overlayVisible)
            {
                if (overlaySwapState == false)
                {
                    $("#sailing-world-link").removeClass("home-screen-campaign-overlay-hidden");
                    $("#campaign-link").addClass("home-screen-campaign-overlay-hidden");
                    overlaySwapState = true;
                }
                else
                {
                    $("#sailing-world-link").addClass("home-screen-campaign-overlay-hidden");
                    $("#campaign-link").removeClass("home-screen-campaign-overlay-hidden");
                    overlaySwapState = false;
                }
            }
        }, 2500);
    }
    else
    {
        $("#sailing-world-link").show();
        $("#campaign-link").hide();
    }
    
    //if ( !browserIsMobileSafari )
    //{
    //    $("#video-player").get(0).load();
    //}
    
    $("#video-player").on( "playing", function() {
        videoPlaying = true;
        $("#play-button").css( "display", "none" );
    });
    
    $("#video-player").on( "pause", function() {
        videoPlaying = false;
        $("#play-button").css( "display", "block" );
    });
    
    $("#video-player").on( "timeupdate", function() {
        videoPlaying = true;
        var playbackTime = $("#video-player").get(0).currentTime;
        updateHalo( playbackTime );

        if ( !heroVideoPlayed && playbackTime > 80 )
        {
            heroVideoPlayed = true;
            sendVideoMark( 111888 );
        }
    });

    $("#play-button").on( "click", function() {
        playVideo();
    });

    // Only auto-play the video if the user loads the top of the page (default)
    console.log(pageSection);
    if ( pageSection === 0 ) {
        $("video").on("canplay", function () {
            // Delay the video start by one second since FF starts playing before the events are registered
            setTimeout(function () {
                // Only start the video if we are still at the top of the page
                if ( $(window).scrollTop() < $(window).height() * 0.5 )
                {
                    playVideo();
                }
            }, 1000);
        });
    }
    else
    {
        var sectionName;

        if ( pageSection < 10 )
        {
            sectionName = 'section-0' + pageSection;
        }
        else
        {
            sectionName = 'section-' + pageSection;
        }

        $(".home-screen-campaign-overlay").hide();
        var sectionDiv = document.getElementsByClassName( sectionName )[0].scrollIntoView();
    }

    var loaderCurtain = $('#loader-curtain');
    if ( loaderCurtain != null )
    {
        loaderCurtain.css( 'display', 'none' );
    }

    $(".load-hidden").css( 'opacity', 1.0 );
});

$(window).resize(function() {
    cssLayout();
});

$(window).scroll(function () {
	var divHeight = getEffectiveWindowHeight();
	var divIndex = $(window).scrollTop() / divHeight;
    
    // Special case for iPhones in protrait mode
    if ( browserIsMobileSafari && $(window).width() < 760 )
    {
        var mHeight;
        if ( isOldIPhone )
        {
            if ( $(window).width() < $(window).height() )
                mHeight = divHeight * 1.0;
            else
                mHeight = divHeight * 1.15;
        }
        else
        {
            mHeight = divHeight * 1.22;
        }

        $(".plx-content").css('min-height', mHeight + 'px');
        $(".fg").css('height', mHeight * 0.98 + 'px');
    }
    else
    {
        $(".plx-content").css( 'min-height', divHeight * 1.1 + 'px' );
    }

    if ( divIndex > 0.8 && videoPlaying ) 
    {
        $("#video-player").get(0).pause();
    }
    
    if ( divIndex < 0.7 && !videoPlaying )
    {
        if ( !browserIsMobileSafari
             ||
             (browserIsMobileSafari 
              && 
              ($(window).innerWidth() > 400 && $(window).innerHeight() > 400)) )
        {
            playVideo();
        }
    }

    if ( !hasScrolled && $(window).scrollTop() > (campaignImageName == null ? 50 : 150) )
    {
        overlayVisible = false;
        setTimeout(function(){
            $(".home-screen-campaign-overlay").hide(500);
        }, (campaignImageName == null ? 500 : 2000));

        hasScrolled = true;
    }

    if ( hasScrolled && $(window).scrollTop() == 0 && !videoPlaying)
    {
        hasScrolled = false;
        overlayVisible = true;
        if ( campaignImageName != null )
        {
            overlaySwapState = false;
            $("#campaign-link").removeClass("home-screen-campaign-overlay-hidden");
            $("#sailing-world-link").addClass("home-screen-campaign-overlay-hidden");
            $("#campaign-link").show(500);
            $("#sailing-world-link").show(500);
        }
        else
        {
            $("#sailing-world-link").show(500);
        }
    }
});

function getEffectiveWindowHeight()
{
    var divHeight = $(window).height() * 1;
    if ( window.screen.height < 760 || window.screen.width < 760 )
    {
        // On iPhone - Account for full screen viewing in landscape mode by using
        // the screen size for the DIV, not the browser's window size
        if ( $(window).width() > $(window).height() )
        {
            divHeight = window.screen.width;
        }

        // Special case for old iPhones
        if ( isOldIPhone )
        {
            if ( $(window).width() > $(window).height() )
            {
                divHeight = window.screen.width;
            }
            else
            {
                divHeight = window.screen.height;
            }
        }
    }

    //console.log('EFFECTIVE HEIGHT = ' + divHeight + 'px / original height = ' + $(window).height() + 'px');
    return divHeight;
}

function cssLayout()
{
    var divHeight = getEffectiveWindowHeight();
    var videoHeight = $(window).innerWidth() * 9.0 / 16.0;
    if ( browserIsMobileSafari )
    {
        if ( $(window).innerHeight() > $(window).innerWidth() )
        {
            // Portrait mode
            if ( $(window).innerWidth() > 400 && $(window).innerWidth() < 760 )
                $("#intro-video").css( 'top', '205px' );
            else if ( $(window).innerWidth() > 770 )
                $("#intro-video").css( 'top', '242px' );
            else
                $("#intro-video").css( 'top', ($(window).innerHeight() - videoHeight) * 0.43 + 'px' );

            // Set foreground height to browser's height
            $('.fg.mobile').css( 'height', divHeight + 'px' );
        }
        else
        {
            // Landscape mode
            if ( $(window).innerHeight() < 400 )
                $("#intro-video").css( 'top', '0px' );   // iPhone
            else
                $("#intro-video").css( 'top', '40px' );  // iPad

            // Force foreground into full screen when rending in landscape (title bar makes browser window < 100% height)
            $('.fg.mobile').css( 'height', window.screen.width + 'px' );
        }
    }
    else
    {
        $("#intro-video").css( 'top', ($(window).innerHeight() - videoHeight) * 0.4 + 'px' );
    }
    
    //$(".float-left-header").css( 'width', $(window).width() - 40 + 'px' );
}

function playVideo()
{
    var playPromise = $("#video-player").get(0).play();

    // In browsers that don’t yet support this functionality, playPromise won’t be defined.
    if (playPromise !== undefined) {
        playPromise.then(function() {
            // Automatic playback started!
            setTimeout(function(){
                if ( campaignImageName != null )
                {
                    $("#campaign-link").hide(500);
                }
                $("#sailing-world-link").hide(500);
                overlayVisible = false;
            }, 1000);
        }).catch(function(error) {
            //console.log('VIDEO-PLAY: ' + error);
            // Automatic playback failed. Catch this so it doesn't accumulate in the console
        });
    }
}

function updateHalo( time )
{
    //color = fac.getColor(document.getElementById('video-player'));
    //$("#intro").css( "background-color", color );
    
    if ( time < 6 ) $("#intro").css( "background-color", "#000000" );
    else if ( time < 10 ) $("#intro").css( "background-color", "#943a35" );
    else if ( time < 15 ) $("#intro").css( "background-color", "#c7d5e5" );
    else if ( time < 23 ) $("#intro").css( "background-color", "#c8c8c8" );
    else if ( time < 94 ) $("#intro").css( "background-color", "#f4f4f4" );
    else if ( time < 141 ) $("#intro").css( "background-color", "#303237" );
    else $("#intro").css( "background-color", "#000000" );
}

function showBoatTypeOverlay( overlayName )
{
    var screenHeight = $(window).innerHeight();
    $('#boat-type-overlay').show();
    $('#boat-type-overlay').css('height', screenHeight / 0.9);
    $('#boat-type-overlay').css('top', $(window).scrollTop());

    $('#boat-type-dinghy').hide();
    $('#boat-type-keelboat').hide();
    $('#boat-type-club').hide();
    $('#boat-type-grandprix').hide();
    $('#boat-type-selector').hide();
    $('#boat-type-dinghy').hide();
    $('#boat-type-keelboat').hide();
    $('#boat-type-club').hide();
    $('#boat-type-grandprix').hide();
    $('#boat-type-calypso').hide();

    $('#boat-type-' + overlayName).show();

    lastBoatTypeOverlay = 'home';
}

function showBoatTypeDetail( boatType )
{
    // Keep track of the last actual boat type as we do a secondary overlay for Calypso
    if ( boatType === 'lastboattype' )
    {
        boatType = lastBoatTypeOverlay;
        if ( boatType === '' )
        {
            boatType = 'selector';
        }
    }
    else if ( boatType !== 'calypso' )
    {
        lastBoatTypeOverlay = boatType;
    }

    $('#boat-type-selector').hide();
    $('#boat-type-dinghy').hide();
    $('#boat-type-keelboat').hide();
    $('#boat-type-club').hide();
    $('#boat-type-grandprix').hide();
    $('#boat-type-calypso').hide();

    if ( boatType == 'selector' )
    {
        $('#boat-type-selector').show();
    }
    else if ( boatType == 'dinghy' )
    {
        $('#boat-type-dinghy').show();
    }
    else if ( boatType == 'keelboat' )
    {
        $('#boat-type-keelboat').show();
    }
    else if ( boatType == 'club' )
    {
        $('#boat-type-club').show();
    }
    else if ( boatType == 'grandprix' )
    {
        $('#boat-type-grandprix').show();
    }
    else if ( boatType == 'calypso' )
    {
        $('#boat-type-calypso').show();
    }
    else
    {
        hideBoatTypeOverlay();
    }
}

function hideBoatTypeOverlay()
{
    lastBoatTypeOverlay = "";
    $('#boat-type-overlay').hide();
}

function addVideoState( videoTagId, videoRef )
{
    var state = null;
    videoOverlayStates.forEach(function (item) {
        if ( item.name === videoTagId )
        {
            state = item;
        }
    });
    if ( state == null )
    {
        state = {name: videoTagId,
                 ref: videoRef,
                 initialized: false,
                 played: false };
        videoOverlayStates.push( state );
    }

    return state;
}

function showVideoOverlay( videoTagId, videoRef ) {
    var videoState = addVideoState(videoTagId, videoRef);

    $('#' + videoTagId + '-overlay').show();

    if (videoState.initialized == false)
    {
        $('#' + videoTagId + '-player').on("ended", function () {
            hideVideoOverlay(videoTagId);
        });

        $('#' + videoTagId + '-player').on("timeupdate", function () {
            var playbackTime = $('#' + videoTagId + '-player').get(0).currentTime;
            if (!videoState.played && playbackTime > 30) {
                videoState.played = true;
                sendVideoMark(videoState.ref);
            }
        });

        videoState.initialized = true;
    }

    var screenHeight = $(window).innerHeight();
    var maxVideoHeight = screenHeight * 0.9 - 90;
    var videoHeight = Math.min( maxVideoHeight, $(window).innerWidth() * 9.0 / 16.0 );
    var videoWidth = videoHeight * 16.0 / 9.0;
    var playerHeight = videoHeight + 100; // room for the playbar at the bottom
    var topMargin = (screenHeight - playerHeight) / 2.0;
    var sidePadding = (($(window).innerWidth() - videoWidth) / 2.0) / $(window).innerWidth() * 90.0;
    $('#' + videoTagId + '-overlay').css('height', screenHeight / 0.9);
    $('#' + videoTagId + '-overlay').css('top', $(window).scrollTop());
    $('#' + videoTagId + '-player').css('top', topMargin);
    $('#' + videoTagId + '-player').css('height', playerHeight);
    $('#' + videoTagId + '-player').css('padding', '0 ' + sidePadding + '% 0 ' + sidePadding + '%');

    $('#' + videoTagId + '-player').get(0).currentTime = 0;

    setTimeout( function() {
        var rplayPromise = $('#' + videoTagId + '-player').get(0).play();

        // In browsers that don’t yet support this functionality, playPromise won’t be defined.
        if (rplayPromise !== undefined) {
            rplayPromise.then(function () {
                // Automatic playback started!
            }).catch(function (error) {
                // Automatic playback failed. Catch this so it doesn't accumulate in the console
            });
        }
    }, 500 );
}

function hideVideoOverlay( videoTagId )
{
    $('#' + videoTagId + '-player').get(0).pause();

    $('#' + videoTagId + '-overlay').hide();

    $('#' + videoTagId + '-player').get(0).currentTime = 0;
}

function showSailhubOverlay()
{
    var screenHeight = $(window).innerHeight();
    $('#sailhub-overlay').show();
    $('#sailhub-overlay').css('height', screenHeight / 0.9);
    $('#sailhub-overlay').css('top', $(window).scrollTop());
}

function hideSailhubOverlay()
{
    $('#sailhub-overlay').hide();
}

function sendVideoMark( vmark )
{
    var url = site.uri.public + '/vmark?ref=' + vmark;
    var debugAjax = (typeof site !== "undefined") && site.debug.ajax;

    return $.ajax({
        type: "GET",
        url: url
    }).fail(function (jqXHR) {
        if (jqXHR.status == 200)
        {
            console.log("vmark sent successfully.");
        } else {
            // Error messages
            if (debugAjax && jqXHR.responseText) {
                document.write(jqXHR.responseText);
                document.close();
            } else {
                console.log("Error (" + jqXHR.status + "): " + jqXHR.responseText);
            }
        }

        return jqXHR;
    }).done(function (response) {
    });
}